import React from "react";
import "./css/ContactUs.css";
import ContactUsForm from "./ContactUsForm";

const ContactUs = () => {


  return (
    <>
      <div className="container-fluid p-4">

        <div>

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1584.3674406867642!2d77.43785024253336!3d23.206809344280536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c43b31957ea09%3A0xd9c7028ee700e17!2sMaharishi%20Dayanand%20School!5e0!3m2!1sen!2sin!4v1739431264351!5m2!1sen!2sin"
            title="school-map"
            style={{
              width: "100%",
              height: "200px",
              border: "5px solid black",
              borderRadius: "10px",
              marginTop: "25px"
            }} allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

        </div>
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="contactleft map text-center">


            <ContactUsForm />

          </div>

          <div className="contactright text-left">
            <div className="titlecontact">
              <h4 className="ssss">School</h4>
              <hr />
              <p className="linkwrap">
                <b className="bbb">Phone: </b>
                <a className="linkwrap mnc" href="tel:+91 87704 07585">+91 0755-2560481</a> ,
                {/* <a className="linkwrap mnc" href="tel:+91 89627 56297">+91 89627 56297</a> */}
              </p>
              <p className="linkwrap">
                <b className="bbb">Address: </b>
                <span className="linkwrap mnc">
                243/1/2 E6 ARERA COLONY NEAR SAI BOARD BHOPAL 462016 MADHYA PRADESH
                </span>
              </p>
              <p className="linkwrap">
                <b className="bbb">Email: </b>
                <a className="linkwrap mnc" href="mailto:maharishids1986@gmail.com">maharishids1986@gmail.com</a>
              </p>


            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default ContactUs;
